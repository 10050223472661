<script>
  import { onMount } from "svelte";
  import { default as Vex } from "vexflow";
  const VF = Vex.Flow;

  let el;
  let mounted = false;
  export let noteDuration = 8;
  export let pattern = "RLRL RLRL RLRL RLRL";

  const removeChildren = (parent) => {
    while (parent.lastChild) {
      parent.removeChild(parent.lastChild);
    }
  };

  const render = async (pattern) => {
    let beats = pattern.split(" ").length;
    let notesPerBeat = pattern.split(" ")[0].length;
    noteDuration = Math.max(
      8,
      notesPerBeat < 4 ? 8 : notesPerBeat < 8 ? 16 : notesPerBeat < 16 ? 32 : 64
    );
    pattern = pattern.replace(/\s/g, "");

    removeChildren(el);

    // Create a VexFlow renderer attaced to the DIV element "boo"
    var renderer = new VF.Renderer(el, VF.Renderer.Backends.SVG);

    // Size our SVG:
    renderer.ctx.setViewBox(0, 0, 600, 110);

    // And get a drawing context:
    var context = renderer.getContext();

    // Create a stave at position 10, 40 of width 400 on the canvas.
    var stave = new VF.Stave(0, 10, 600, { left_bar: false, right_bar: false });

    // Add a clef and time signature.
    stave.addClef("percussion");

    // Connect it to the rendering context and draw!
    stave.setContext(context).draw();

    let notes = [];
    let note = null;
    let stickings = [];

    for (let hand in pattern) {
      if (pattern[hand] !== "-") {
        note = new VF.StaveNote({
          clef: "treble",
          keys: ["c/5"],
          duration: `${noteDuration}`,
        });

        if (pattern[hand].toLowerCase() !== pattern[hand]) {
          note.addArticulation(
            0,
            new VF.Articulation("a>").setPosition("above")
          );
        }
      } else {
        note = new VF.StaveNote({
          clef: "treble",
          keys: ["c/5"],
          duration: `${noteDuration}`,
          type: "r",
        });
      }

      let sticking = new VF.TextNote({
        text: pattern[hand] != "-" ? pattern[hand].toUpperCase() : " ",
        duration: `${noteDuration}`,
        font: { family: "Courier New, monospace", size: 15 },
        justification: VF.TextNote.Justification.LEFT,
        line: 9,
      });

      if (
        hand > 0 &&
        hand != pattern.length - 1 &&
        hand % notesPerBeat === notesPerBeat - 1
      ) {
        note.applyTickMultiplier(notesPerBeat - 1, 1);
        sticking.applyTickMultiplier(notesPerBeat - 1, 1);
      }

      notes.push(note);
      stickings.push(sticking);
    }

    let beams = [];
    let tuplets = [];

    if (beats > 1) {
      for (let b = 0; b < beats; b++) {
        let notesForBeat = notes.slice(
          b * notesPerBeat,
          (b + 1) * notesPerBeat
        );
        beams.push(new VF.Beam(notesForBeat));
        if (notesPerBeat >= 5 || notesPerBeat % 2 !== 0) {
          tuplets.push(
            new VF.Tuplet(notesForBeat, {
              notes_occupied: notesPerBeat,
              num_notes: notesPerBeat,
              bracketed: false,
            })
          );
        }
      }
    }
    VF.Formatter.FormatAndDraw(context, stave, notes);
    beams.forEach((beam) => beam.setContext(context).draw());
    if (tuplets) tuplets.forEach((beam) => beam.setContext(context).draw());
    VF.Formatter.FormatAndDraw(context, stave, stickings);
  };

  onMount(async () => {
    mounted = true;
  });

  $: {
    if (mounted) {
      render(pattern);
    }
  }
</script>

<div bind:this="{el}">
  <svg></svg>
</div>

<style>
  div > svg {
    width: 100% !important;
    height: 100% !important;
  }
</style>
