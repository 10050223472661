<script>
  import Sticking from "./components/Sticking.svelte";
  const RIGHT = "r";
  const LEFT = "l";
  const ANY = "";

  const urlParams = new URLSearchParams(window.location.search);

  let exercises = [];
  let minConsecutive = urlParams.get("minConsecutive") || 1;
  let maxConsecutive = urlParams.get("maxConsecutive") || 4;
  let numExercises = urlParams.get("numExercises") || 6;
  let beats = urlParams.get("beats") || 4;
  let notesPerBeat = urlParams.get("notesPerBeat") || 4;
  let startingHand = urlParams.get("startingHand") || ANY;
  let favorHand = urlParams.get("favorHand") || ANY;
  let includeAccents = urlParams.get("includeAccents") || 0;
  let includeRests = urlParams.get("includeRests") || 1;

  const random = (min, max) => {
    return Math.random() * (max - min) + min;
  };

  const updateSettings = (e) => {
    e.preventDefault();
    const settings = new FormData(document.getElementById("settings"));
    numExercises = settings.get("numExercises");
    minConsecutive = settings.get("minConsecutive");
    maxConsecutive = settings.get("maxConsecutive");
    beats = settings.get("beats");
    notesPerBeat = settings.get("notesPerBeat");
    startingHand = settings.get("startingHand");
    favorHand = settings.get("favorHand");
    includeAccents = settings.get("includeAccents");
    includeRests = settings.get("includeRests");
    showExercise();
  };

  const generateExercise = () => {
    let exerciseGroups = [];
    let consecutive = 0;
    let consecutiveHand = "";
    let leftHandProbability =
      favorHand === ANY ? 0.5 : favorHand === LEFT ? 0.75 : 0.25;

    for (let i = 0; i < beats; i++) {
      let beat = [];
      let hand = null;
      for (let j = 0; j < notesPerBeat; j++) {
        if (startingHand && i === 0 && j === 0) {
          hand = startingHand;
        } else if (consecutiveHand && consecutive < minConsecutive) {
          hand = consecutiveHand;
        } else if (consecutive >= maxConsecutive) {
          // If we've had too many of the same hand in a row, switch them
          hand = consecutiveHand === RIGHT ? LEFT : RIGHT;
        } else {
          hand = Math.random() < leftHandProbability ? LEFT : RIGHT;
        }

        if (includeAccents && Math.random() > 0.75) {
          beat.push(hand.toUpperCase());
        } else if (includeRests && Math.random() > 0.75) {
          beat.push("-");
        } else {
          beat.push(hand);
        }

        consecutive = hand === consecutiveHand ? consecutive + 1 : 1;
        consecutiveHand = hand;
      }

      exerciseGroups.push(beat.join(""));
    }
    return exerciseGroups.join(" ");
  };

  const showExercise = () => {
    const maxRetries = 5;
    let retries = 0;
    exercises = [];
    for (let i = 0; i < numExercises; i++) {
      let exercise = generateExercise();
      if (retries < maxRetries && exercises.some((e) => e === exercise)) {
        // Poor-man's deduplication
        i--;
        retries++;
      } else {
        retries = 0;
        exercises.push(exercise);
      }
    }
  };
  showExercise();
</script>

<main>
  <div class="container">
    <div class="exercises">
      <h1>Drum Exercise Generator</h1>
      <div class="exercises-grid">
        {#each exercises as exercise, i}
          <div class="exercise">
            <Sticking pattern="{exercise}" />
          </div>
        {/each}
      </div>
      <button on:click="{showExercise}" class="next">Next</button>
    </div>
    <div class="settings">
      <h2>Settings</h2>
      <form id="settings" on:input="{updateSettings}">
        <label for="numExercise">Number of Exercises</label>
        <input
          type="number"
          name="numExercises"
          id="numExercises"
          value="{numExercises}"
          min="1"
        />

        <label for="minConsecutive">Minimum Consecutive</label>
        <input
          name="minConsecutive"
          id="minConsecutive"
          type="number"
          value="{Math.min(minConsecutive, maxConsecutive)}"
          max="{maxConsecutive}"
          min="1"
        />

        <label for="maxConsecutive">Maximum Consecutive</label>
        <input
          name="maxConsecutive"
          id="maxConsecutive"
          type="number"
          value="4"
          max="{beats * notesPerBeat}"
          min="2"
        />

        <label for="beats">Beats</label>
        <input
          name="beats"
          id="beats"
          type="number"
          value="4"
          max="8"
          min="1"
        />

        <label for="notesPerBeat">Notes per Beat</label>
        <input
          name="notesPerBeat"
          id="notesPerBeat"
          type="number"
          value="4"
          max="12"
          min="2"
        />

        <label for="startingHand">Starting Hand</label>
        <select
          name="startingHand"
          id="startingHand"
          bind:value="{startingHand}"
        >
          <option value="{ANY}">No preference</option>
          <option value="{LEFT}">Left</option>
          <option value="{RIGHT}">Right</option>
        </select>

        <label for="favorHand">Favor Hand</label>
        <select name="favorHand" id="favorHand" bind:value="{favorHand}">
          <option value="{ANY}">Equal</option>
          <option value="{LEFT}">Left</option>
          <option value="{RIGHT}">Right</option>
        </select>

        <label for="includeAccents">Include Accents</label>
        <input
          name="includeAccents"
          id="includeAccents"
          type="checkbox"
          bind:checked="{includeAccents}"
        />

        <label for="includeRests">Include Rests</label>
        <input
          name="includeRests"
          id="includeRests"
          type="checkbox"
          bind:checked="{includeRests}"
        />
      </form>
    </div>
  </div>
</main>

<style>
  main {
    padding: 1em;
    margin: 0 auto;
  }

  .container {
    display: grid;
    grid-template-areas: "exercises settings";
    grid-template-columns: auto min-content;
    grid-column-gap: 1em;
  }

  .exercises-grid {
    display: grid;
    grid-column-gap: 2em;
    grid-template-columns: auto auto;
  }

  .exercise {
    width: 100%;
    height: auto;
    padding-bottom: 2em;
    min-width: 20em;
  }

  .settings {
    grid-area: "settings";
    background: #eeeeee;
    text-align: left;
    padding: 1em;
    min-width: 15em;
  }

  .settings * {
    width: 100%;
  }

  button {
    margin-top: 2em;
  }

  @media print {
    .settings,
    .next {
      display: none;
    }
  }
</style>
